import React, { useState } from "react"
import { graphql } from "gatsby"
import Nav from "../components/UI/Nav/Nav"
import { Container, Row } from "react-bootstrap"
import HeadImage from "../components/UI/HeadImage/HeadImage"
import Modal from "../components/UI/Modal/Modal"
import ModalCourse from "../components/UI/SectionsCoursesItems/ModalCourse/ModalCourse"
import SectionsCoursesItems from "../components/UI/SectionsCoursesItems/SectionsCoursesItems"
import SEO from "../components/seo"
import Footer from "../components/UI/Footer/Footer"

const CoursesPage = ({ data }) => {
  const [modalShow, setModalShow] = useState(false)

  const [modalCourseShow, setModalCourseShow] = useState(false)

  const [courseSelected, setCourseSelected] = useState()

  const coursesSections = {}
  const images = [
    data.courses1.childImageSharp.fluid.src,
    data.courses2.childImageSharp.fluid.src,
  ]
  data.prismic.allCursoss.edges.map(items => {
    if (items.node.tipo_de_curso.nombre_de_curso[0].text in coursesSections)
      coursesSections[items.node.tipo_de_curso.nombre_de_curso[0].text][
        "items"
      ].push({
        title: items.node.tipo_de_curso.nombre_de_curso[0].text,
        cost: items.node.costo,
        address: items.node.direccion,
        description: items.node.descripcion,
        date: items.node.fecha_de_curso,
        time: items.node.horario,
        mode: items.node.modalidad,
        location: items.node.sede,
        month: items.node.mes_de_curso,
        bank: items.node.informacion_bancaria.banco,
        clabe: items.node.informacion_bancaria.clabe,
        razon_social: items.node.informacion_bancaria.razon_social,
        cuenta: items.node.informacion_bancaria.cuenta,
        rfc: items.node.informacion_bancaria.rfc,
      })
    else
      coursesSections[items.node.tipo_de_curso.nombre_de_curso[0].text] = {
        title: items.node.tipo_de_curso.nombre_de_curso[0].text,
        description: items.node.tipo_de_curso.descripcion_de_curso
          ? items.node.tipo_de_curso.descripcion_de_curso.map(item => item.text)
          : [],
        items: [
          {
            title: items.node.tipo_de_curso.nombre_de_curso[0].text,
            cost: items.node.costo,
            address: items.node.direccion,
            description: items.node.descripcion,
            date: items.node.fecha_de_curso,
            time: items.node.horario,
            mode: items.node.modalidad,
            location: items.node.sede,
            month: items.node.mes_de_curso,
            bank: items.node.informacion_bancaria.banco,
            clabe: items.node.informacion_bancaria.clabe,
            razon_social: items.node.informacion_bancaria.razon_social,
            cuenta: items.node.informacion_bancaria.cuenta,
            rfc: items.node.informacion_bancaria.rfc,
          },
        ],
      }
    return null
  })
  return (
    <Container fluid>
      <SEO />
      <Modal show={modalShow} handleClose={() => setModalShow(false)} />
      <Nav active="CURSOS" />
      <Row>
        <HeadImage
          image={data.coursesheader.childImageSharp.fluid}
          height="auto"
        >
          <p className="small-size">
            Contamos con los entrenamientos necesarios para el personal POE, con
            programas de capacitación de acuerdo a los ordenamientos aplicables.
          </p>
        </HeadImage>
      </Row>
      <Row>
        <ModalCourse
          course={courseSelected}
          show={modalCourseShow}
          handleClose={() => setModalCourseShow(false)}
        />
        <SectionsCoursesItems
          courseSelected={course => {
            setCourseSelected(course)
            setModalCourseShow(true)
          }}
          sections={coursesSections}
          images={images}
        />
      </Row>
      <Footer />
    </Container>
  )
}

export default CoursesPage

export const pageQuery = graphql`
  query coursesQuery {
    prismic {
      allCursoss(sortBy: mes_de_curso_ASC) {
        edges {
          node {
            costo
            direccion
            descripcion
            fecha_de_curso
            horario
            identificador_de_curso
            modalidad
            mes_de_curso
            sede
            tipo_de_curso {
              _linkType
              ... on PRISMIC_Tipo_de_cursos {
                nombre_de_curso
                descripcion_de_curso
              }
            }
            informacion_bancaria {
              _linkType
              ... on PRISMIC_Informacion_bancaria {
                razon_social
                banco
                clabe
                cuenta
                rfc
              }
            }
          }
        }
      }
    }
    coursesheader: file(relativePath: { eq: "index.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    courses1: file(relativePath: { eq: "courses-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    courses2: file(relativePath: { eq: "courses-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
