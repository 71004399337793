import React from "react"
import { Modal, Col, Container, Row } from "react-bootstrap"

import "./ModalCourse.css"

const ModalCourse = ({ show, handleClose, course }) => {
  if (course) {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="modal-course"
      >
        <Modal.Header closeButton>
          <Modal.Title>{course.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <span>Descripción:</span> {course.description}
          </p>
          <p>
            <span>Sede:</span> {course.location}
          </p>
          <p>
            <span>Dirección:</span> {course.address}
          </p>
          <p>
            <span>Fecha:</span> {course.date}
          </p>
          <p>
            <span>Horario:</span> {course.time}
          </p>
          <p>
            <span>Costo:</span> {course.cost}
          </p>
          <div>
            <h4>Información de pago</h4>
            <p className="my-0">{course.razon_social}</p>
            <Container fluid className="payment-modal">
              <Row>
                <Col xs="6" sm="5" lg="3" className="item-payment">
                  <p>Banco</p> <p>{course.bank}</p>
                </Col>
                <Col xs="6" sm="5" lg="3" className="item-payment">
                  <p>Cuenta</p> <p>{course.cuenta}</p>
                </Col>
                <Col xs="6" sm="5" lg="3" className="item-payment">
                  <p>CLABE</p> <p>{course.clabe}</p>
                </Col>
                <Col xs="6" sm="5" lg="3" className="item-payment">
                  <p>RFC </p> <p>{course.rfc}</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <h4>Confirma tu asistencia</h4>
            <p>atencionspr@gmail.com</p>
            <p>442-850-0328</p>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
  return null
}

export default ModalCourse
