import React from "react"
import { Table } from "react-bootstrap"
import "./TableCourses.css"

const TableCourses = ({ items, title, selectedCourse }) => {
  const months = {
    0: "Enero",
    1: "Febrero",
    2: "Marzo",
    3: "Abril",
    4: "Mayo",
    5: "Junio",
    6: "Julio",
    7: "Agosto",
    8: "Septiembre",
    9: "Octubre",
    10: "Noviembre",
    11: "Diciembre",
  }
  return (
    <div className="course-items mt-4">
      <h3>{title}</h3>
      <Table responsive>
        <thead>
          <tr>
            <th>FECHA</th>
            <th>SEDE</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((course, index) => {
            return (
              <tr key={`course${index}`}>
                <td>
                  {months[new Date(course.month).getMonth()]}{" "}
                  {new Date(course.month).getFullYear()}
                </td>
                <td>{course.location}</td>
                <td className="font-weight-bold">
                  <button
                    className="btn btn-link"
                    onClick={() => selectedCourse(course)}
                    onKeyDown={() => {}}
                  >
                    DETALLES
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default TableCourses
