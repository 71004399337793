import React from "react"

import SectionsItems from "../SectionsItems/SectionsItems"

import TableCourses from "./TableCourses/TableCourses"

import "./SectionsCoursesItems.css"

const SectionsCoursesItems = ({ sections, images, courseSelected }) => {
  const sectionsObjects = []
  Object.keys(sections).forEach(function (key, index) {
    const courseItems = { Presencial: [], Virtual: [] }

    sections[key].items.map(courseItem => {
      courseItems[courseItem.mode].push({
        location: courseItem.location.map(item => item.text)[0],
        month: courseItem.month,
        title: courseItem.title,
        description: courseItem.description.map(item => item.text)[0],
        cost: courseItem.cost.map(item => item.text)[0],
        address: courseItem.address?.map(item => item.text)[0],
        date: courseItem.date.map(item => item.text)[0],
        time: courseItem.time.map(item => item.text)[0],
        bank: courseItem.bank.map(item => item.text)[0],
        clabe: courseItem.clabe.map(item => item.text)[0],
        razon_social: courseItem.razon_social.map(item => item.text)[0],
        cuenta: courseItem.cuenta.map(item => item.text)[0],
        rfc: courseItem.rfc.map(item => item.text)[0],
      })
      return null
    })
    sectionsObjects.push(
      <SectionsItems
        key={`SectionCoursesItems-${index}`}
        images={images}
        title={key}
        index={index}
      >
        <p>{sections[key].description}</p>

        {courseItems["Presencial"].length > 0 ? (
          <TableCourses
            selectedCourse={courseSelected}
            title="PRÓXIMOS CURSOS PRESENCIALES"
            items={courseItems["Presencial"]}
          />
        ) : null}
        {courseItems["Virtual"].length > 0 ? (
          <TableCourses
            selectedCourse={courseSelected}
            title="PRÓXIMOS CURSOS VIRTUALES"
            items={courseItems["Virtual"]}
          />
        ) : null}
      </SectionsItems>
    )
  })
  return sectionsObjects
}

export default SectionsCoursesItems
